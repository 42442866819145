<template>
  <div class="form-group">
    <div class="custom-control custom-checkbox mb-2" @click="updateValue">
      <input v-model="value" type="checkbox" class="custom-control-input">
      <label class="custom-control-label">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: [Boolean],
      default: false,
    },

    label: {
      required: false,
      type: [String],
      default: '',
    },
  },

  methods: {
    updateValue() {
      this.$emit('input', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-control-label {
  user-select: none;
  cursor: pointer;
  &:before, &:After {
    width: 1rem;
    height: 1rem;
    top: 1px;
  }
}
</style>
